.projects {
  padding: 0rem 6rem;
}

.projects-grid1,
.projects-grid2 {
  width: 100%;
  display: grid;
  //dense grid

  grid-template-rows: 1fr;
  grid-gap: 2rem;
}
.projects-grid1 {
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 2rem;
}
.projects-grid2 {
  grid-template-columns: repeat(2, 1fr);
}

.projects-title {
  padding-bottom: 5rem;
  grid-row: 1 /1;
  grid-column: 1/ -1;
}
.shadow-title {
  text-shadow: 1px 0px 0px #181818, 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.project-title {
  grid-row: 2/2;
  grid-column: 2/2;
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
}

.projects-1,
.projects-2,
.projects-3,
.projects-5,
.projects-6,
.projects-4 {
  position: relative;
  display: grid;
  border-radius: 0.5rem;
  grid-template-rows: 1rem 72% 1fr;
  grid-template-columns: 2rem 1fr;
  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}
.summarize-p {
  display: block;
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
  position: absolute;
  top: 29%;
  font-size: 1.6rem;
  text-align: center;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 20% 15%;
}

.projects-1:hover .summarize-p,
.projects-2:hover .summarize-p,
.projects-3:hover .summarize-p,
.projects-4:hover .summarize-p,
.projects-6:hover .summarize-p,
.projects-5:hover .summarize-p {
  height: 16%;
  transition: all 0.2s ease;
}

.img {
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/-1;
  border-radius: 0.5rem;
}
#rectangle_11_ek1 {
  background: rgba(249, 133, 91, 1);
  padding: 0.8rem 2rem;
}

#rectangle_11_ek2 {
  background: rgba(249, 133, 91, 1);
  padding: 1.7rem 5.2rem;
}

.box {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #fff;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box:hover {
  -webkit-transform: scale(1.07, 1.07);
  transform: scale(1.07, 1.07);
}
.box:hover::after {
  opacity: 1;
}

#see_the_project {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
#see_the_code {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}

#rectangle_11_ek5 {
  background: rgb(105, 105, 105);
  padding: 0.8rem 2rem;
  margin-left: 1rem;
}

#project-links {
  grid-row: 3/3;
  grid-column: 2/2;
}

#project-links2 {
  grid-row: 3/3;
  grid-column: 2/2;
  margin-left: 16rem;
}

#rectangle_figma,
#rectangle_javascript,
#rectangle_react,
#rectangle_react-2,
#rectangle_solidity,
#rectangle_next,
#rectangle_stripe,
#rectangle_firebase,
#rectangle_rails {
  position: absolute;
  grid-row: 2/3;
  grid-column: 2/2;
  right: 1rem;
  margin-top: 1rem;
  padding: 0.1rem 0.7rem;
  border-radius: 1rem;
  // border: 3px solid rgb(84, 11, 255);
  background: rgb(78, 78, 78);
}

#rectangle_solidity1 {
  position: absolute;
  grid-row: 2/3;
  grid-column: 2/2;
  right: 6rem;
  margin-top: 1rem;
  padding: 0.1rem 0.7rem;
  border-radius: 1rem;
  // border: 3px solid rgb(84, 11, 255);
  background: rgb(78, 78, 78);
}
#rectangle_figma {
  background: linear-gradient(0.2turn, #eb4d34, #9d56f7, #46b7f5);
}
#rectangle_next {
  background: #151515;
}
#rectangle_firebase {
  background: #1967d2;
  right: 11.8rem;
}
#rectangle_stripe {
  background: #9d56f7;
  right: 6rem;
}
#rectangle_rails {
  background: linear-gradient(0.2turn, #b82925, #7d1916);
}
#rectangle_javascript {
  background: #e6cb1e;
}
#rectangle_react {
  background: #31aed1;
  right: 9rem;
}
#rectangle_react-2 {
  background: #31aed1;
  right: 11.8rem;
}

#rectangle_react3 {
  background: #31aed1;
  position: absolute;
  grid-row: 2/3;
  grid-column: 2/2;
  margin-top: 1rem;
  padding: 0.1rem 0.7rem;
  border-radius: 1rem;
  // border: 3px solid rgb(84, 11, 255);
  right: 13rem;
}

#rectangle_react4 {
  background: #31aed1;
  position: absolute;
  grid-row: 2/3;
  grid-column: 2/2;
  margin-top: 1rem;
  padding: 0.1rem 0.7rem;
  border-radius: 1rem;
  // border: 3px solid rgb(84, 11, 255);
  right: 6.2rem;
}
#rectangle_graphql {
  background: #d2478e;
  position: absolute;
  grid-row: 2/3;
  grid-column: 2/2;
  margin-top: 1rem;
  padding: 0.1rem 0.7rem;
  border-radius: 1rem;
  // border: 3px solid rgb(84, 11, 255);
  right: 21rem;
}
#rectangle_hardhat {
  background: #e6cb1e;
  position: absolute;
  grid-row: 2/3;
  grid-column: 2/2;
  margin-top: 1rem;
  padding: 0.1rem 0.7rem;
  border-radius: 1rem;
  // border: 3px solid rgb(84, 11, 255);
  right: 13.2rem;
}
