@import './components/nav';
@import './components/header';
@import './components/projects';
@import './components/about';
@import './components/contact';
@import './components/contactform';
@import './base/base';
@import './base/typography';
@import './base/animations';

$primary-color: #28b485;
$color-grey-dark: #777;

.shadow {
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.5));
}
ul {
  list-style-type: none;
}

@media only screen and (max-width: 1200px) {
  .li-language {
    margin-left: 0rem;
    display: flex;
  }
  .projects-grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .projects-selector {
    display: none;
  }

  .heading-primary--main,
  .heading-primary--sub {
    font-size: 10rem;
    line-height: 10rem;
  }
  .paragraph {
    width: 60%;
    padding: 2.9rem 0;
    font-size: 1.5rem;
  }
  .social-media-title {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 980px) {
  .nav__logo-box--logo {
    filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.08));
    height: 4rem;
  }
  #rectangle_react {
    right: 7.5rem;
  }
  #rectangle_react-2 {
    right: 9rem;
  }

  .summarize-p {
    font-size: 1.2rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 20% 2.5rem;
  }

  .nav__navbar--navlink {
    letter-spacing: 0.1rem;
    padding: 0 1.5rem;
    font-size: 1.4em;
  }

  #project-links2 {
    margin-left: 11.5rem;
  }

  .title {
    font-size: 2.8rem;
  }

  .project-title {
    font-size: 2.5rem;
  }

  #stack-h {
    font-size: 2rem;
    color: rgb(78, 78, 78);
  }
  .stack {
    padding: 5rem 0;
  }

  .contact-links > * {
    padding-bottom: 1rem;
  }
  #rectangle_11_ek1,
  #rectangle_11_ek5 {
    padding: 0.6rem 1.5rem;
  }
  #see_the_project {
    font-size: 1.2rem;
    text-align: center;
  }
  #see_the_code {
    font-size: 1.2rem;
    text-align: center;
  }

  .social-media--box {
    display: flex;
    padding-left: 2rem;
    padding-top: 0.12rem;
    justify-content: space-around;
    width: 21rem;
  }
  .social-media-logo {
    height: 2rem;
  }
}

@media only screen and (max-width: 900px) {
  .heading-primary--main,
  .heading-primary--sub {
    font-size: 7.5rem;
    line-height: 7.5rem;
  }
  .nav__logo-box {
    top: 6rem;
    left: 6rem;
  }

  #rectangle_react {
    right: 7rem;
  }

  #rectangle_react-2 {
    right: 9.4rem;
  }

  .summarize-p {
    font-size: 1.1rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 20% 2rem;
  }

  .paragraph {
    padding: 2.9rem 0;
    font-size: 1.2rem;
  }
  .social-media-title {
    font-size: 1.2rem;
  }
  .nav {
    padding: 6rem 6rem 0rem 6rem;
  }
  .nav__logo-box--logo {
    height: 3rem;
  }
  .nav__navbar--navlink {
    letter-spacing: 0rem;
    padding: 0 0.5rem;
    font-size: 1.4em;
  }

  .title {
    font-size: 2.4rem;
  }

  .project-title {
    font-size: 2.1rem;
  }

  #stack-h {
    font-size: 1.5rem;
    color: rgb(78, 78, 78);
  }
  .stack {
    padding: 8rem 0;
  }

  #rectangle_11_ek1 {
    padding: 0.6rem 1.5rem;
  }
  #rectangle_11_ek2 {
    padding: 1rem 4.5rem;
  }
  .social-media--box {
    padding-left: 1.5rem;
    width: 18rem;
  }
  .social-media-logo {
    height: 2rem;
  }

  .stack-logo {
    height: 2rem;
    width: 1.8rem;
    margin-right: 0.7rem;
  }
  .header {
    padding: 8.5rem 6rem 5.5rem 6rem;
  }
  .p {
    font-size: 1.3rem;
  }
  .contact {
    margin-top: 10rem;
    padding-top: 5rem;
    padding-bottom: 0rem;
    height: 67rem;
  }

  .about {
    height: 40rem;
    padding: 0rem 8.5rem;
    grid-gap: 15rem;
    gap: 15rem;
  }
  #author {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
  .contact > * {
    width: 47%;
  }
}

@media only screen and (max-width: 750px) {
  .heading-primary--main,
  .heading-primary--sub {
    font-size: 8rem;
    line-height: 8rem;
  }
  #see_the_project,
  #see_the_code {
    font-size: 1.5rem;
    text-align: center;
  }
  .projects-grid1,
  .projects-grid2 {
    grid-template-columns: 1fr;
  }

  #rectangle_react {
    right: 9rem;
  }

  #rectangle_react-2 {
    right: 11rem;
  }

  .summarize-p {
    font-size: 1.5rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 18% 1.5rem;
  }
  .paragraph {
    padding: 3rem 0;
    font-size: 1.6rem;
  }
  .social-media-title {
    font-size: 1.8rem;
  }
  .nav {
    padding: 4rem 6rem 0rem 0rem;
  }

  .nav__logo-box--logo {
    height: 4rem;
  }
  .nav__logo-box {
    top: 4rem;
    left: 6.5rem;
  }

  .nav__navbar--navlink {
    letter-spacing: 0rem;
    padding: 0 0.5rem;
    font-size: 1.5em;
  }

  .title {
    font-size: 2.7rem;
  }

  .project-title {
    font-size: 2.7rem;
  }
  .projects-title {
    padding-bottom: 3rem;
    font-size: 2.7rem;
  }
  #stack-h {
    font-size: 2rem;
    color: rgb(78, 78, 78);
  }
  .stack {
    padding: 8rem 0;
  }

  #rectangle_11_ek1,
  #rectangle_11_ek5 {
    padding: 0.6rem 1.5rem;
  }

  #rectangle_11_ek5 {
    margin-left: 3.4rem;
  }
  #rectangle_11_ek2 {
    padding: 1rem 4.5rem;
  }
  .social-media--box {
    padding-left: 1.5rem;
    width: 28rem;
  }
  .social-media-logo {
    height: 2.4rem;
  }

  .stack-logo {
    height: 2rem;
    width: 1.8rem;
    margin-right: 0.7rem;
  }
  .header {
    padding: 8.5rem 8.5rem 5.5rem 6.5rem;
  }
  .p {
    font-size: 1.4rem;
  }
  .contact {
    margin-top: 10rem;
    padding-top: 5rem;
    padding-bottom: 0rem;
    height: 72rem;
  }

  .about {
    margin-top: -5rem;
    height: 35rem;
    padding: 0rem 6.5rem;
    gap: 10rem;
  }
  .projects {
    padding: 0rem 6rem;
  }

  #get_in_touch {
    font-size: 1.5rem;
  }
  .contact-form-button {
    margin-top: 0.6rem;
    border: 1px solid #000000;
    background: black;
    padding: 1.2rem;
  }

  .contact > * {
    width: 57%;
  }

  .email {
    font-size: 1.4rem;
  }

  .projects-1,
  .projects-2,
  .projects-3,
  .projects-5,
  .projects-6,
  .projects-4 {
    grid-template-rows: 1rem 77% 1fr;
    grid-template-columns: 1.2rem 1fr;
  }

  .projects-grid1,
  .projects-grid2 {
    grid-gap: 1.5rem;
  }

  .projects-grid1 {
    padding-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 630px) {
  .projects-grid1,
  .projects-grid2 {
    grid-gap: 3rem;
  }
  .projects-grid1 {
    padding-bottom: 3rem;
  }

  .resume > * {
    padding: 0.8rem 0;
  }
  .heading-primary--main,
  .heading-primary--sub {
    font-size: 7rem;
    line-height: 7rem;
  }
  .paragraph {
    padding: 1.8rem 0;
    font-size: 1.4rem;
    width: 80%;
  }

  .social-media-logo {
    height: 2rem;
  }

  #rectangle_11_ek1 {
    padding: 0.4rem 1.2rem;
  }
  #rectangle_11_ek5 {
    padding: 0.4rem 1.2rem;
  }
  #rectangle_11_ek5 {
    margin-left: 2.5rem;
  }
  #rectangle_11_ek2 {
    padding: 0.7rem 3.5rem;
  }
  .social-media--box {
    padding-left: 1.2rem;
    width: 23rem;
  }

  .about {
    height: 27rem;
    padding: 0rem 4.5rem;
    gap: 8rem;
  }

  .stack {
    padding: 8rem 0;
  }
  .p {
    font-size: 1.4rem;
  }

  .contact {
    margin-top: 10rem;
    padding-top: 3rem;
    padding-bottom: 0rem;
    height: 63rem;
  }

  .projects {
    padding: 0rem 4.5rem;
  }
  .nav {
    padding: 4rem 4rem 0rem 0rem;
  }
  .header {
    padding: 6.5rem 6.5rem 4.5rem 4.5rem;
  }
  .projects-title {
    font-size: 2.5rem;
  }
  .nav__logo-box {
    position: absolute;
    top: 4rem;
    left: 4.5rem;
  }
  .contact > * {
    width: 63%;
  }
  #stack-h {
    font-size: 1.5rem;
  }
  .title {
    font-size: 2.5rem;
  }

  #author {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
  .nav__logo-box--logo {
    height: 3.2rem;
  }

  form input,
  form textarea {
    border: 0;
    outline: 0;
    padding: 0.7em;
    display: block;
    width: 100%;
    margin-top: 0.7em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
    font-size: 1.2rem;
  }
  .about {
    margin-top: -5rem;
    height: 30rem;
  }

  .stack-logo {
    height: 1rem;
    width: 1rem;
    margin-right: 0.4rem;
  }

  .p-language {
    padding: 0.1rem 0;
  }

  form textarea {
    height: 10rem;
  }

  .email {
    font-size: 1.2rem;
  }
  .nav__navbar--navlink {
    font-size: 1.4em;
  }

  .projects-1,
  .projects-2,
  .projects-3,
  .projects-4 {
    grid-template-rows: 1rem 75% 1fr;
  }
}

@media only screen and (max-width: 530px) {
  .heading-primary--main,
  .heading-primary--sub {
    font-size: 5.6rem;
    line-height: 5.6rem;
  }
  .social-media-title {
    font-size: 1.3rem;
  }
  .social-media--box {
    padding-left: 1.4rem;
    width: 19rem;
  }
  .nav__navbar--navlink {
    font-size: 1.3em;
  }
  .nav__logo-box--logo {
    height: 3rem;
  }
  .title {
    font-size: 2.2rem;
  }
  .project-title {
    font-size: 2.5rem;
  }
  #see_the_project,
  #see_the_code {
    font-size: 1.3rem;
    text-align: center;
  }
  #rectangle_11_ek5 {
    margin-left: 1.3rem;
  }
  .summarize-p {
    font-size: 1.2rem;
  }
  .p {
    font-size: 1.2rem;
  }
  #rectangle_react {
    right: 7.9rem;
  }
  #rectangle_react-2 {
    right: 10rem;
  }
}

@media only screen and (max-width: 450px) {
  .summarize-p {
    margin: 20% 25%;
  }

  .projects-1:hover .summarize-p,
  .projects-2:hover .summarize-p,
  .projects-3:hover .summarize-p,
  .projects-4:hover .summarize-p,
  .projects-6:hover .summarize-p,
  .projects-5:hover .summarize-p {
    height: 13%;
    transition: all 0.2s ease;
  }

  .projects-grid1,
  .projects-grid2 {
    grid-template-columns: 1fr;
  }
  .nav__logo-box--logo {
    filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.08));
    height: 2.8rem;
  }
  #rectangle_react {
    right: 7.5rem;
  }
  #rectangle_react-2 {
    right: 9.5rem;
  }
  .summarize-p {
    font-size: 1.1rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 20% 2rem;
    line-height: 1.3rem;
  }

  #project-links2 {
    margin-left: 11rem;
  }

  .nav__navbar--navlink {
    letter-spacing: 0rem;
    padding: 0 0.5rem;
    font-size: 1.1em;
  }
  .nav__logo-box {
    position: absolute;
    top: 4.15rem;
    left: 3.6rem;
  }

  .nav {
    padding: 4.5rem 2.5rem 0rem 0rem;
  }
  .header {
    padding: 4.5rem 2.5rem 2.5rem 2.5rem;
  }

  .paragraph {
    width: 90%;
    padding: 2rem 0;
    font-size: 1.2rem;
  }
  .heading-primary--main,
  .heading-primary--sub {
    font-size: 4.6rem;
    line-height: 4.7rem;
  }

  .social-media-title {
    font-size: 1.2rem;
  }

  .social-media-logo {
    height: 1.7rem;
  }

  .social-media--box {
    padding-left: 1rem;
    width: 60%;
  }
  .projects {
    padding: 0rem 2.5rem;
  }

  .title {
    font-size: 2.2rem;
  }

  .project-title {
    font-size: 2rem;
  }

  #rectangle_11_ek1,
  #rectangle_11_ek5 {
    padding: 0.6rem 1.4rem;
  }
  #see_the_project {
    font-size: 1.2rem;
  }
  #see_the_code {
    font-size: 1.2rem;
  }

  .about {
    display: flex;
    flex-direction: column;
    height: 35rem;
    padding: 0rem 3rem;
    gap: 0rem;
    margin-bottom: 20rem;
    align-items: center;
    text-align: center;
  }

  .li-language {
    margin: 0;
    padding-top: 0.35rem;
  }

  #stack-h {
    font-size: 1.2rem;
    text-align: left;
  }

  .contact-links > * {
    display: flex;
    justify-content: center;
  }
  form {
    // max-width: 600px;
    text-align: center;
    margin: 1rem auto;
  }

  .contact > * {
    width: 70%;
  }
  #author {
    margin-top: 0rem;
    font-size: 1rem;
  }

  .stack {
    width: 100%;
    padding: 3rem 0;
  }

  .p {
    width: 80%;
    margin: 0 auto;
    font-size: 1.2rem;
    text-align: center;
  }
  #white {
    width: 90%;
  }

  .projects-1,
  .projects-2,
  .projects-3,
  .projects-4 {
    grid-template-rows: 1rem 73% 1fr;
    grid-template-columns: 1.2rem 1fr;
  }
}

@media only screen and (max-width: 350px) {
  .summarize-p {
    font-size: 1.1rem;
    background-color: rgba(0, 0, 0, 0.6);
    margin: 18% 2rem;
    line-height: 1.2rem;
  }
  .about {
    margin-bottom: 20rem;
  }

  .nav {
    padding: 3.5rem 2.5rem 0rem 0rem;
  }
  .contact {
    margin-top: 8.5rem;
    padding-top: 2rem;
    padding-bottom: 0rem;
    height: 58rem;
  }

  .heading-primary--main,
  .heading-primary--sub {
    font-size: 4rem;
    line-height: 4rem;
  }

  .nav__logo-box--logo {
    height: 2.2rem;
  }

  .social-media-title {
    width: 30%;
  }
  .nav__logo-box {
    position: absolute;
    top: 3.5rem;
    left: 2.5rem;
  }

  .p {
    width: 80%;
    margin: 0 auto;
    font-size: 1.2rem;
    text-align: center;
  }
  #see_the_project {
    font-size: 1rem;
  }
  #rectangle_react {
    right: 6.5rem;
  }
  #rectangle_react-2 {
    right: 8rem;
  }
  #rectangle_11_ek5 {
    margin-left: 0rem;
  }
  #rectangle_11_ek1,
  #rectangle_11_ek5 {
    padding: 0.6rem 1.4rem;
  }
  .projects-1,
  .projects-2,
  .projects-3,
  .projects-4 {
    grid-template-rows: 1rem 67% 1fr;
    grid-template-columns: 1.2rem 1fr;
  }
}

@media only screen and (max-width: 320px) {
  .summarize-p {
    margin: 12% 5%;
    font-size: 1rem;
  }

  .projects-1:hover .summarize-p,
  .projects-2:hover .summarize-p,
  .projects-3:hover .summarize-p,
  .projects-4:hover .summarize-p,
  .projects-6:hover .summarize-p,
  .projects-5:hover .summarize-p {
    height: 23%;
    transition: all 0.2s ease;
  }

  .header {
    padding: 3.5rem 2.5rem 2.5rem 2.5rem;
  }

  .projects {
    padding: 0rem 2.5rem;
  }

  .nav__navbar {
    display: none;
  }
  .nav__logo-box {
    position: absolute;
    top: 3.8rem;
    left: 2.5rem;
  }

  .stack {
    width: 100%;
    display: grid;
    padding: 0rem 0;
    margin: 4.5rem 0;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: repeat(1fr);
    align-items: baseline;
    justify-content: center;
    gap: 0.5rem;
  }

  .p {
    width: 80%;
    margin: 0 auto;
    font-size: 1rem;
    text-align: center;
  }
  #stack-h {
    font-size: 1rem;
    margin: 0rem;
    padding: 0rem;
  }
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 0.3;
  cursor: pointer;
  border: none;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}
