.nav {
  width: 100%;
  margin: 0 auto;
  padding: 6rem 3rem;
  &__logo-box {
    position: absolute;
    top: 5rem;
    left: 9rem;

    &--logo {
      filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.08));
      height: 5rem;
    }
  }
  &__navbar {
    display: flex;
    justify-content: flex-end;

    &--navlink {
      letter-spacing: 0.12rem;
      display: inline-block;
      padding: 0 2.5rem;
      color: #000000;
      text-decoration: none;
      font-size: 1.6em;
    }
  }
}

