.about {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  height: 40rem;
  padding: 8.5rem 8.5rem;
  gap: 15rem;
}

.resume,
.stack {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.resume {
  margin-top: 8rem;
  grid-row: 1/1;
  & > * {
    padding: 1.5rem 0;
  }
}
.stack {
  grid-row: 1/-1;
}

.p-language {
  display: inline-block;
  justify-self: center;
  text-justify: distribute;
}
.li-language {
  margin-bottom: 0rem;
  margin-left: 0rem;
  padding-top: 0.65rem;
  display: flex;
}

.stack-logo {
  height: 2.6rem;
  width: 2.4rem;
  margin-right: 1rem;
}

#stack-h {
  margin-top: 1.5rem;
  margin-bottom: 0.2rem;
  font-size: 2.5rem;
  color: rgb(78, 78, 78);
}
