.header {
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: minmax(min-content, max-content);
  padding: 8.5rem;
  grid-template-rows: 1fr min-content minmax(6rem, min-content) 1fr;
}
.heading-primary--main {
  grid-row: 1/2;
  font-size: 13.9em;
  line-height: 12.9rem;
  font-weight: bold;
  color: #000000;
}
.heading-primary--sub {
  grid-row: 2/3;
  font-size: 13.9em;
  line-height: 13rem;
  font-weight: bold;
  color: #f9855b;
}
.paragraph {
  font-weight: 400;
  grid-row: 3/4;
  width: 70%;
  padding: 2.9rem 0;
  font-size: 1.8rem;
  color: #717273;
}
.social-media {
  display: flex;
  grid-row: 4/5;
}
.social-media-title {
  font-size: 2rem;
  font-weight: bold;
  color: #727274;
}
.social-media--box {
  display: flex;
  padding-left: 2rem;
  padding-top: 0.12rem;
  justify-content: space-around;
  width: 28rem;
}

.social-media-logo {
  height: 2.5rem;

  &:hover {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
}
