.contact {
  margin-top: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background: rgba(249, 133, 91, 1);
  padding-top: 5rem;
  padding-bottom: 1rem;
  height: 78rem;

  & > * {
    width: 43%;
  }

  &-form > :nth-child(3) {
    display: block;
  }

  &-form-button {
    margin-top: 1rem;
    border: 1px solid #000000;
    background: rgba(0, 0, 0, 1);
    padding: 1.7rem;
  }
}
#white {
  color: #ffffff;
  text-align: center;
}

#get_in_touch {
  font-size: 1.7rem;
  white-space: nowrap;
  display: block;
  color: #ffffff;
  width: 100%;
}

#author {
  margin-top: 2rem;
  display: block;
  font-size: 1.6rem;
  color: #ffd9cc;
}

.contact-links > * {
  display: inline-block;
}

.email {
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.6rem;
}
