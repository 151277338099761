body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

.title {
  overflow: hidden;
  font-size: 3.6rem;
  font-weight: bold;
  color: #000000;
}
.p {
  overflow: hidden;
  font-size: 1.6rem;
  text-align: left;
  color: #727274;
}
